import React, { FC } from 'react';
import { graphql } from 'gatsby';

import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import Layout from 'layout/Layout';
import Image from 'common/Image';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';

import { ContactProps } from './models';

import './Contact.scss';

const Contact: FC<{ data: ContactProps }> = ({
  data: {
    contact: { urls, seo, name, image, body },
  },
}) => {
  const { title: seoTitle, keywords: seoKeywords, description: seoDescription } = seo;

  return (
    <Layout headerTransparent>
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={seoTitle}
        data={{
          metaTitle: seoTitle,
          metaDescription: seoDescription,
          metaKeywords: seoKeywords,
        }}
      />
      <div className="contact">
        <div className="contact__image">
          <Image imageData={image} alt={name} className="w-100" />
          <h1>{name}</h1>
        </div>
        <div className="container section-container">
          <div className="row pt-5 mt-4 pb-4 justify-content-center">
            <div className="col">
              <DangerouslySetInnerHtml html={body} element="div" className="mb-0" />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($url: String) {
    contact(url: { eq: $url }) {
      urls {
        lang
        href
      }
      seo {
        ...SEOStructureFragment
      }
      name
      image {
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920, quality: 100)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920, quality: 100)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920, quality: 100)
          }
        }
      }
      body
    }
  }
`;

export default Contact;
